<template>
  <div class="header-bg">
    <div class="header-inner container">
      <div class="col-logo">
        <div v-if="siteData.frontLogo">
          <img :src="siteData.frontLogo" :alt="siteData.siteName" style="height: 40px" />
        </div>
        <div v-else class="site-tt">
          {{ siteData.siteName }}
        </div>
      </div>
      <div class="col-menu">
        <el-menu
          :router="true"
          :default-active="activeIndex"
          mode="horizontal"
          background-color="#4377fb"
          text-color="#fff"
          active-text-color="#FFD550"
        >
          <el-menu-item v-if="siteData.props.moduleExam" index="/pages/exam" class="module-exam">
            在线考试
          </el-menu-item>
          <template v-if="!isHidden">
            <el-menu-item v-if="siteData.props.moduleExam" class="module-exam" index="/pages/repo">
              刷题训练
            </el-menu-item>
            <el-menu-item index="/pages/notice"> 公告 </el-menu-item>
          </template>
          <el-menu-item index="/pages/uc"> 我的 </el-menu-item>
        </el-menu>
      </div>
      <div class="right-user">
        <el-badge :value="unread" :max="99" :hidden="unread === 0">
          <div class="top-avatar" @click="msgClick">
            <yf-avatar :src="avatar" :size="38" />
          </div>
        </el-badge>
        <div>
          {{ realName }}
        </div>
        <a v-if="roleType === 2" class="alink" @click="toAdmin">管理</a> <a @click="logout">退出</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import { getToken } from '@/utils/auth'
import YfAvatar from '@/components/YfAvatar/index.vue'
import { quOrExam } from '@/api/paper/exam'
export default {
  components: { YfAvatar },

  data() {
    return {
      activeIndex: '/pages/exam',
      msgTimer: null,
      unread: 0,
      isHidden: true,
    }
  },
  computed: {
    ...mapGetters(['userId', 'avatar', 'realName', 'siteData', 'roleType', 'unreadMsg']),
  },
  watch: {
    unreadMsg: {
      handler(val) {
        this.unread = val
      },
    },
  },
  created() {
    this.getQuOrExam()
    this.focusMenu()
    this.fetchMsg()
  },

  methods: {
    // 选定菜单
    focusMenu() {
      const activeMenu = this.$route.meta.activeMenu

      if (activeMenu) {
        this.activeIndex = activeMenu
        return
      }
      const path = this.$route.path.split('/')
      const prefix = path[0] + '/' + path[1] + '/' + path[2]
      console.log(prefix)
      this.activeIndex = prefix
    },

    isActive(url) {
      if (this.activeIndex === url) {
        return 'nav active'
      }
      return 'nav'
    },

    msgClick() {
      // 指定消息
      localStorage.setItem('uc:tab:active', 'msg')
      this.activeIndex = '/pages/uc'
      this.$router.push('/pages/uc/im')
    },

    async logout() {
      const that = this

      this.$confirm('确定要退出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
      })
        .then(() => {
          that.$store.dispatch('user/logout').then(() => {
            that.$router.push('/pages/login/login')
          })
        })
        .catch(() => {})
    },

    async getQuOrExam() {
      quOrExam().then((res) => {
        this.isHidden = res.data
      })
    },

    toAdmin() {
      // 获取管理首页
      const index = store.getters.index
      this.$router.push({ path: index })
    },

    // 获取消息
    fetchMsg() {
      // 清理
      if (this.msgTimer) {
        clearInterval(this.msgTimer)
      }

      const token = getToken()
      if (!token) {
        return
      }

      // 30秒读取一次消息
      this.msgTimer = setInterval(() => {
        store.dispatch('user/fetchMsg')
      }, 30000)

      // 立即读取一次
      store.dispatch('user/fetchMsg')
    },
  },
}
</script>
<style scoped>
.header-bg {
  height: 60px;
  background: #4377fb;
  display: flex;
  justify-content: center;
}

.header-inner {
  display: flex;
  align-items: center;
}

.right-user {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  line-height: 60px;
  align-items: center;
  max-width: 240px;
}

.site-tt {
  font-weight: 700;
  font-size: 22px;
  color: #eee;
  text-align: left;
}

.right-user a,
.right-user div {
  color: #efefef;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}

.right-user a:last-child {
  margin-right: 0 !important;
}

.right-user a:hover {
  color: #ffd550;
}

/deep/ .alink {
  color: #ffd550 !important;
}

/deep/ .alink:hover {
  color: #f94e3e !important;
}

.nav {
  color: #fff;
  border: none;
  background: transparent;
  font-weight: 700;
  font-size: 16px;
  padding: 5px 10px 5px 10px;
}

.active {
  color: #000055;
  background: #ffd550;
}

.nav:hover {
  color: #000055;
  background: #ffd550;
}

.col-logo {
  display: flex;
  align-items: center;
  height: 60px;
  max-width: 250px;
}

.col-menu {
  display: flex;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/deep/ .top-avatar {
  text-align: right;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: -15px !important;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  font-weight: 700;
  font-size: 16px;
}

::v-deep .el-menu--horizontal > .el-menu-item {
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
    font-weight: 700;
    font-size: 16px;
    padding: 0 5px;
  }

  ::v-deep .el-menu--horizontal > .el-menu-item {
    font-size: 16px;
    font-weight: 700;
    padding: 0 5px;
  }
}

/*::v-deep .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {*/
/*  background-color: transparent;*/
/*}*/

/*::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal .el-menu-item:not(.is-disabled):focus {*/
/*  outline: none;*/
/*  color: #FFD550;*/
/*}*/

/*::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover {*/
/*  background: transparent;*/
/*  color: #FFD550;*/
/*}*/
</style>
