<template>
  <div :class="{ collapse: collapse }" class="sidebar-logo-container">
    <transition name="sidebarLogoFade">
      <img v-if="siteData.backLogo" :src="siteData.backLogo" class="main-logo" />

      <!-- <div v-if="collapse" key="collapse" class="sidebar-logo-link" @click="toIndex">
        <img v-if="siteData.backLogo" :src="siteData.backLogo" class="sidebar-logo" />
        <h1 class="sidebar-title">{{ siteData.siteName }}</h1>
      </div>
      <div v-else key="expand" class="sidebar-logo-link" @click="toIndex">
        <img v-if="siteData.backLogo" :src="siteData.backLogo" class="sidebar-logo" />
        <h1 class="sidebar-title">{{ siteData.siteName }}</h1>
      </div> -->
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['siteData']),
  },
  methods: {
    toIndex() {
      // 管理首页
      const index = store.getters.index
      this.$router.push(index)
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    cursor: pointer;

    & .sidebar-logo {
      width: 28px;
      height: 28px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  .main-logo {
    width: auto;
    height: 28px;
    vertical-align: middle;
    margin-right: 12px;
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0;
    }
  }
}
</style>
