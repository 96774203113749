var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.siteData.backLogo
          ? _c("img", {
              staticClass: "main-logo",
              attrs: { src: _vm.siteData.backLogo },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }