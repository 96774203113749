var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-bg" }, [
    _c("div", { staticClass: "header-inner container" }, [
      _c("div", { staticClass: "col-logo" }, [
        _vm.siteData.frontLogo
          ? _c("div", [
              _c("img", {
                staticStyle: { height: "40px" },
                attrs: {
                  src: _vm.siteData.frontLogo,
                  alt: _vm.siteData.siteName,
                },
              }),
            ])
          : _c("div", { staticClass: "site-tt" }, [
              _vm._v(" " + _vm._s(_vm.siteData.siteName) + " "),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "col-menu" },
        [
          _c(
            "el-menu",
            {
              attrs: {
                router: true,
                "default-active": _vm.activeIndex,
                mode: "horizontal",
                "background-color": "#4377fb",
                "text-color": "#fff",
                "active-text-color": "#FFD550",
              },
            },
            [
              _vm.siteData.props.moduleExam
                ? _c(
                    "el-menu-item",
                    {
                      staticClass: "module-exam",
                      attrs: { index: "/pages/exam" },
                    },
                    [_vm._v(" 在线考试 ")]
                  )
                : _vm._e(),
              !_vm.isHidden
                ? [
                    _vm.siteData.props.moduleExam
                      ? _c(
                          "el-menu-item",
                          {
                            staticClass: "module-exam",
                            attrs: { index: "/pages/repo" },
                          },
                          [_vm._v(" 刷题训练 ")]
                        )
                      : _vm._e(),
                    _c("el-menu-item", { attrs: { index: "/pages/notice" } }, [
                      _vm._v(" 公告 "),
                    ]),
                  ]
                : _vm._e(),
              _c("el-menu-item", { attrs: { index: "/pages/uc" } }, [
                _vm._v(" 我的 "),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-user" },
        [
          _c(
            "el-badge",
            { attrs: { value: _vm.unread, max: 99, hidden: _vm.unread === 0 } },
            [
              _c(
                "div",
                { staticClass: "top-avatar", on: { click: _vm.msgClick } },
                [_c("yf-avatar", { attrs: { src: _vm.avatar, size: 38 } })],
                1
              ),
            ]
          ),
          _c("div", [_vm._v(" " + _vm._s(_vm.realName) + " ")]),
          _vm.roleType === 2
            ? _c("a", { staticClass: "alink", on: { click: _vm.toAdmin } }, [
                _vm._v("管理"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("a", { on: { click: _vm.logout } }, [_vm._v("退出")]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }