var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.siteData.props.wechatLogin ||
    _vm.siteData.props.faceLogin ||
    _vm.siteData.props.cropLogin ||
    _vm.siteData.props.dingLogin
    ? _c(
        "div",
        { staticStyle: { "line-height": "35px", "margin-top": "10px" } },
        [
          _c("div", { staticClass: "title-line" }, [_vm._v("其它方式登录")]),
          _c("div", { staticStyle: { display: "flex" } }, [
            _vm.siteData.props.wechatLogin
              ? _c(
                  "div",
                  {
                    staticClass: "third-item wechat-icon",
                    on: { click: _vm.wechatLogin },
                  },
                  [
                    _c("svg-icon", { attrs: { "icon-class": "login-wx" } }),
                    _c("div", [_vm._v("微信登录")]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.siteData.props.cropLogin
              ? _c(
                  "div",
                  {
                    staticClass: "third-item wx2-icon",
                    on: { click: _vm.cropWechatLogin },
                  },
                  [
                    _c("svg-icon", { attrs: { "icon-class": "login-wx2" } }),
                    _c("div", [_vm._v("企业微信")]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.siteData.props.dingLogin
              ? _c(
                  "div",
                  {
                    staticClass: "third-item dd-icon",
                    on: { click: _vm.dingLogin },
                  },
                  [
                    _c("svg-icon", { attrs: { "icon-class": "login-dd" } }),
                    _c("div", [_vm._v("钉钉登录")]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }